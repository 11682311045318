<template>
  <div class="p-4" v-show="PageTitle">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ PageTitle }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'SatPracticeDetailAll', query: $route.query }"
          >
            Browse
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          PDF
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-alert type="warning" show-icon class="mb-3">
      <h6 slot="title" class="mt-2">
        Notice
      </h6>
      <div style="font-size: 14px;" class="mb-2">
        This "browse" feature is only available for teachers to help you view
        all questions on the same page, which may be helpful for you during your
        preparation or the actual class. You may also use the "Download PDF"
        button to create PDFs for yourself, but you are
        <strong>NOT allowed</strong> to distribute electronic or physical copies
        of the PDF to students. Students should practice electronically and can
        view the test and all the questions from their
        <router-link :to="{name:'SATResults'}">Results</router-link> page after
        completing the practice test.
      </div>
    </el-alert>
    <el-button
      type="success"
      @click="downloadPDF('#content', PageTitle)"
      style="width: 100%;"
    >
      <i class="fas fa-download"></i>
      Download PDF
    </el-button>
    <div class="mt-4 mb-3">
      <el-checkbox
        v-model="showPassage"
        label="Passage"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showQuestion"
        label="Entire Question"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showOptions"
        label="Choices"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showAnswer"
        label="Answer"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showExplanation"
        label="Explanation"
        size="medium"
        border
      ></el-checkbox>
      <el-checkbox
        v-model="showLabels"
        label="Domain and Skills"
        size="medium"
        border
      ></el-checkbox>
    </div>
    <!-- <h4>
      {{ PageTitle }}
    </h4>
    <hr class="separate-line" /> -->
    <div ref="questions-all">
      <div id="content" ref="targetDom" class="p-4">
        <div class="small">
          <h4>
            {{ PageTitle }}
          </h4>
          <hr />
        </div>
        <div
          v-for="(question, index) in practice.exam.exam_questions"
          :key="question.id"
          class="small"
          style="padding-bottom: 2rem;"
        >
          <QuestionTableDetail
            :isAdmin="isRoleAdmin()"
            :question="question.question"
            :order="index + 1"
            :showQuestion="showQuestion"
            :showPassage="showPassage"
            :showOptions="showOptions"
            :showAnswer="showAnswer"
            :showExplanation="showExplanation"
            :showLabels="showLabels"
          ></QuestionTableDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
// import PracticeCard from "@/views/satComposePractice/components/Practice.vue";
import QuestionTableDetail from "@/views/allQuestions/QuestionTableDetail";
import $ from "jquery";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, QuestionTableDetail },

  mixins: [Common, roleMixin],

  props: [],
  data() {
    return {
      showPassage: true,
      showQuestion: true,
      showOptions: true,
      showAnswer: false,
      showExplanation: false,
      showLabels: false,
      showCreateNewQuestionDialog: false,
      practice: {
        exam: {
          title: null,
          exam_questions: []
        },
        subject_type: null,
        subject_id: null
      },
      practices: [],
      subjects: []
    };
  },
  computed: {
    PageTitle() {
      let title = this.practice.exam.title ? this.practice.exam.title : "";
      return title;
    },
    id() {
      return this.$route.query.id;
    },
    isEdit() {
      let isEdit = 0;
      if (this.$route.query.isEdit) {
        isEdit = Number(this.$route.query.isEdit);
      }
      return isEdit;
    }
  },
  watch: {
    showPassage() {
      this.initMathJaxPlugin();
    },
    showExplanation() {
      this.initMathJaxPlugin();
    },
    showAnswer() {
      if (this.showAnswer) {
        this.showOptions = true;
      }
    },
    showOptions() {
      this.initMathJaxPlugin();
    },
    showQuestion() {
      this.initMathJaxPlugin();
    },
    questions() {
      this.initMathJaxPlugin();
    }
  },

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    this.getPractices();
    if (this.id && Number(this.id) > 0) {
      await this.getPracticeDetail();
      await this.initMathJaxPlugin();
    } else {
      this.isEdit = true;
    }
    if (this.$route.query.subject_id) {
      this.practice.subject_id = this.$router.query.subject_id;
    }
    this.$nextTick(async () => {
      this.setImg();
    });
    let vm = this;
    window.onkeydown = function(e) {
      // 屏蔽ctrl+p 打印
      if (e.ctrlKey && e.keyCode == 80) {
        vm.$message({
          dangerouslyUseHTMLString: true,
          message:
            "Please use the  <i class='fas fa-download'></i> <b>Download PDF</b> button below to create a PDF file for this test.",
          type: "warning"
        });
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
    };
  },

  methods: {
    setImg() {
      $(".small img").each(function() {
        if ($(this).attr("src") !== "") {
          $(this).attr("src", $(this).attr("src") + "?" + new Date().getTime());
        }
        $(this).attr("crossorigin", "anonymous");
      });
    },
    isSplit(nodes, index, pageHeight) {
      return (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      );
    },
    getFooterElement(remainingHeight, fillingHeight = 36) {
      let newNode = document.createElement("div");
      newNode.style.background = "#ffffff";
      newNode.style.width = "calc(100% + 8px)";
      newNode.style.marginLeft = "-4px";
      newNode.style.marginBottom = "0px";
      newNode.style.height = remainingHeight + fillingHeight + "px"; // pdf截断需要一个空白位置
      return newNode;
    },
    outPutPdfFn(ele, title) {
      this.$message.warning("Processing... Please wait.");
      let vm = this;
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      vm.$nextTick(async () => {
        let target = vm.$refs.targetDom;
        let pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
        let lableListID = document.getElementsByClassName("small");
        for (let i = 0; i < lableListID.length; i++) {
          let multiple = Math.ceil(
            (lableListID[i].offsetTop + lableListID[i].offsetHeight) /
              pageHeight
          );

          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            console.log(multiple + "Page: " + multiple * pageHeight);
            console.log(this.isSplit(lableListID, i, multiple * pageHeight));

            console.log(i + " offsetTop:" + lableListID[i].offsetTop);
            console.log(i + " offsetHeight:" + lableListID[i].offsetHeight);

            let divParent = lableListID[i].parentNode; // 获取该div的父节点
            let _H =
              multiple * pageHeight -
              (lableListID[i].offsetTop + lableListID[i].offsetHeight);

            console.log(_H);
            let newNode = vm.getFooterElement(_H);
            let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next);
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode);
            }
          }
        }
        this.getPdfShort(ele, title);
      });
    },
    downloadPDF(ele, title) {
      this.outPutPdfFn(ele, title);
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-all"]]);
      });
    },
    async getPractices() {
      const res = await SAT.getPractices();
      this.practices = res.practices;
    },
    async getPracticeDetail() {
      const res = await SAT.getEditorPracticeDetail(this.id);
      this.practice = res.practice;
    },
    showSubject(id) {
      let subjectName = "";
      this.subjects.forEach(subject => {
        if (subject.id == id) {
          subjectName = this.titleCase(subject.name);
        }
      });
      return subjectName;
    }
  }
};
</script>

<style scoped>
#content {
  position: relative;
  width: 1184.56px;
  margin: 0 auto;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>
